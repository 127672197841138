import React from 'react';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import productBuoy from '../../../photos/productBuoy.svg';
import classes from "./EmployeeDetails.module.scss"

function EmployeeDetails(props) {
    const phoneCall = () => {
        return "tel:" + props.user.phone;
    }

    const viber = () => {
        const woPlus = props.user.phone.replace('+', '')
        return "viber://chat?number=%2B" + woPlus;
    }

    const phonePretty = () => {
        const globalCode = props.user.phone.substr(0, 3);
        const countryCode = props.user.phone.substr(3, 3);
        const phone1 = props.user.phone.substr(6, 3);
        const phone2 = props.user.phone.substr(9, 2);
        const phone3 = props.user.phone.substr(11, 2);

        return globalCode + ' (' + countryCode + ') ' + phone1 + ' ' + phone2 + ' ' + phone3;
    }

    return (
        <div className={classes.root}>
            <div className={classes.details}>
                <div className={classes.text}>
                    <Typography variant="h4" component="h2" gutterBottom>
                        {props.user.fullName}
                    </Typography>
                </div>
                <div className={classes.text}>
                    <Typography variant="h5">
                        {props.user.position}
                    </Typography>
                </div>
                <div>
                    <Button href={phoneCall()} type="submit" color="primary" variant="contained">
                        Зателефонувати &nbsp;{phonePretty()}
                    </Button>
                </div>
                <div>
                    <a href={viber()}>
                        <img src={productBuoy} className={classes.buoy} alt="buoy"/>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default EmployeeDetails;
