import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import { Link } from 'react-router-dom';
import logo from '../../photos/logo.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DropdownMenu from './DropdownMenu';
import './styles.scss';

const styles = (theme) => ({
  appBar: {
    background: 'black',
  },
  title: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  rightLink: {
    fontSize: 14,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  image: {
    height: 70,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  phoneNumber: {
    display: 'none',
    position: 'absolute',
    bottom: '-80px',
    left: '-20px',
    background: 'black',
    borderRadius: '4px',
    padding: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
    transition: 'opacity 0.9s ease',
  },
  rightLinkHovered: {
    '& $phoneNumber': {
      display: 'block',
    },
    '& $phoneNumberLink': {
      color: 'white',
    },
  },
  phoneNumberLink: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    marginBottom: '5px',
    padding: '4px',
    transition: 'background-color 0.5s ease',
  },
  phoneNumberLinkHovered: {
    backgroundColor: 'green',
  },
});

const Header = ({ classes }) => {
  const [isPhoneNumberHovered, setIsPhoneNumberHovered] = useState(false);
  const [isLogistLinkHovered, setIsLogistLinkHovered] = useState(false);
  const [isJuristLinkHovered, setIsJuristLinkHovered] = useState(false);
  const [isAccountantLinkHovered, setIsAccountantLinkHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    if (isPhoneNumberHovered) {
      setIsPhoneNumberHovered(false);
    } else {
      setIsPhoneNumberHovered(true);
    }
  };
  

  const handlePhoneNumberClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePhoneNumberMouseEnter = () => {
    setIsPhoneNumberHovered(true);
  };

  const handlePhoneNumberMouseLeave = () => {
    setIsPhoneNumberHovered(false);
  };

  const handleLogistLinkMouseEnter = () => {
    setIsLogistLinkHovered(true);
  };

  const handleLogistLinkMouseLeave = () => {
    setIsLogistLinkHovered(false);
  };

  // const handleJuristLinkMouseEnter = () => {
  //   setIsJuristLinkHovered(true);
  // };

  // const handleJuristLinkMouseLeave = () => {
  //   setIsJuristLinkHovered(false);
  // };

  const handleAccountantLinkMouseEnter = () => {
    setIsAccountantLinkHovered(true);
  };

  const handleAccountantLinkMouseLeave = () => {
    setIsAccountantLinkHovered(false);
  };

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            {/* Змінюємо лого на Link */}
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.image} />
            </Link>
          </div>
          <div className={classes.center}>
            <DropdownMenu />
          </div>
          <div className={classes.right}>
            <div
              className={`${classes.rightLink} ${isDropdownOpen ? 'show' : ''}`}
              onClick={handleDropdownToggle}
            >
              <a
                className={`${classes.rightLink} ${isPhoneNumberHovered && classes.rightLinkHovered}`}
                onMouseEnter={handlePhoneNumberMouseEnter}
                onMouseLeave={handlePhoneNumberMouseLeave}
                onClick={handlePhoneNumberClick}
              >
                <LocalPhoneIcon fontSize="large" style={{ color: 'white' }} />
                <div className={classes.phoneNumber}>
                  <a
                    className={`${classes.phoneNumberLink} ${isLogistLinkHovered && classes.phoneNumberLinkHovered}`}
                    href="tel:+380966341617"
                    onMouseEnter={handleLogistLinkMouseEnter}
                    onMouseLeave={handleLogistLinkMouseLeave}
                  >
                    Логіст
                  </a>
                  {/* <a
                    className={`${classes.phoneNumberLink} ${isJuristLinkHovered && classes.phoneNumberLinkHovered}`}
                    href="tel:+380670110411"
                    onMouseEnter={handleJuristLinkMouseEnter}
                    onMouseLeave={handleJuristLinkMouseLeave}
                  >
                    Юрист
                  </a> */}
                  <a
                    className={`${classes.phoneNumberLink} ${isAccountantLinkHovered && classes.phoneNumberLinkHovered}`}
                    href="tel:+380960968402"
                    onMouseEnter={handleAccountantLinkMouseEnter}
                    onMouseLeave={handleAccountantLinkMouseLeave}
                  >
                    Бухгалтер
                  </a>
                </div>
              </a>
              <a
                className={classes.rightLink}
                href="https://www.instagram.com/hot_logistics/?utm_medium=copy_link"
              >
                <InstagramIcon fontSize="large" style={{ color: 'white' }} />
              </a>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);