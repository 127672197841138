import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import productCurvyLines from '../../photos/productCurvyLines.png';
import helpImage from '../../photos/rost.svg';
import rostImage from '../../photos/help.svg';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#000', // Змінено на чорний фон
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
    textAlign: '-webkit-center',
  },
  image: {
    height: 85,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  word: {
    color: '#4dc247',
  },
});

function ProductValuesJur(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src={productCurvyLines}
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={rostImage}
                alt="rost"
              />
              <Typography variant="h6" className={classes.title}>
                <b className={classes.word}>🥇Фахова підтримка бухгалтерського обліку від професіоналів - це те, що вам потрібно, щоб оптимізувати та систематизувати ваш бізнес.</b>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={helpImage}
                alt="help"
              />
              <Typography variant="h6" className={classes.title}>
                <b className={classes.word}>📈Ми забезпечимо вас всіма необхідними інструментами та використаємо весь наш багаторічний досвід, щоб ваш бізнес процвітав!</b>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValuesJur.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValuesJur);
