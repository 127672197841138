import React from 'react';
import "./ArticlesNew.scss"
import Container from '@material-ui/core/Container';
import article1 from '../../photos/article1.jpg';
import article2 from '../../photos/article2.jpg';
import ShowMoreText from 'react-show-more-text';
import Avatar from '@mui/material/Avatar';
import avatar1 from '../../photos/avatar1.jpg';
import avatar2 from '../../photos/avatar2.jpg';

function Articles() {
    return (
        <Container>
            <div className="blog-card">
                <div className="meta">
                    <div className="photo">
                        <img src={article1} alt="Article 1"/>
                    </div>
                    <ul className="details">
                        <ul>
                        </ul>
                    </ul>
                </div>
                <div className="description">
                    <Avatar
                        src={avatar1}
                        sx={{ width: 80, height: 80 }}/>

                    <h2>Як змінилося життя моєї фірми з появою якісної логістики</h2>
                    <h3>Сергій Каменецький /
                        Директор компанії "Элмон спецсервіс"</h3>
                    <ShowMoreText
                        className="read-more"
                        lines={5}
                        more='Дізнатися більше'
                        less='Менше'
                        expanded={false}>
                        <p> Наша щільна співпраця з цією фірмою розпочалася у 2011р. До цього ми шукали машини на різних ресурсах типу Lardi-trans і т.п.
                            Це займало дуже багато часу, зусиль та нервів у менеджерів нашої фірми. Менеджери змушені були витрачати багато часу на пошук
                            авто та оформлення документів з кожним перевізником окремо. Відповідно, залишалося менше часу на продаж та пошук нових клієнтів.
                            Продаж не зростав. Початок співпраці з цією логістичною компанією все спростив. Простий дзвінок, із зазначенням пункту вивантаження,
                            вирішував проблему наявності потрібного автомобіля за кілька хвилин, максимум – кілька годин. Логісти підшукували автомобіль, а менеджер
                            у цей час міг спокійно займатися продажем та пошуком нових клієнтів. Як результат, оберти швидко збільшилися, нервова напруга
                            спало (що теж важливо). Звичайно, ми розуміли, що переплачуємо кілька сотень гривень на кожному авто за послуги логістики,
                            але ці витрати з лишком окупилися наростаючим продажем, оскільки замість однієї-двох машин на день ми почали відвантажувати 5-10. Документообіг
                            теж значно спростився. Замість десятків різних перевізників, із якими потрібно було обмінюватися кореспонденцією, з'явилася логістична
                            компанія, яка швидко та легко надавала всі необхідні документи, т.ч. Бухгалтерія теж зітхнула з полегшенням... Життя нашої фірми
                            значно спростилася, завдяки появі надійного та якісного партнера. Зараз все працює як годинник - просто робиш заявку і займаєшся справою,
                            в той час як логісти шукають потрібний автомобіль, бухгалтерія отримує первинку вчасно та без проблем. «Скупий платить двічі» особливо часом,
                            нервами та проблемами з документами. Ми вирішили не поскупитися і за це рішення огороджені сторицею. Усім прибуткового бізнесу!
                        </p>
                    </ShowMoreText>
                </div>
            </div>

            <div className="blog-card">
                <div className="meta">
                    <div className="photo">
                        <img src={article2} alt="Article 2"/>
                    </div>
                    <ul className="details">
                        <ul>
                        </ul>
                    </ul>
                </div>
                <div className="description">
                    <Avatar
                        src={avatar2}
                        sx={{ width: 80, height: 80 }}/>
                    <h2>Якісна логістика - це рух вперед.</h2>
                    <h3>Олександр /
                        Директор компанії "Українська Агропромислова Група"</h3>
                    <ShowMoreText
                        className="read-more"
                        lines={5}
                        more='Дізнатися більше'
                        less='Менше'
                        expanded={false}>
                        <p> Якісна логістика - це рух вперед. Ми це відчули ще у 2007 році, коли про слово "outsourcing" мало хто чув.
                            Завдяки тому, що команда "The Hot Logistics" взяла на себе повний контроль та супровід  вантажу, наші менеджери
                            відчули комфорт. Це 100% впевненість за цілісність вантажу. Ми отримали додатковий час на опрацювання нових клієнтів.
                            Це значно розширило нашу клієнтську базу та збільшило наш дохід. Відтоді ми пліч-о-пліч рухаємось вгору. За цей час
                            сформувались довговічні та корисні відносини котрі ми будемо розвивати й надалі.
                            Найголовнішою рисою наших логістичних партнерів є швидке реагування на ситуації ринку автоперевезень та інформування
                            менеджерів Української Агропромислової Групи. *Це сприяє автоматизації продажів, та збільшує їх обсяг на 20-30%.
                        </p>
                    </ShowMoreText>
                </div>
            </div>
        </Container>
    )
}

export default Articles;
