import React, {useState} from 'react';
import {TiChevronLeftOutline, TiChevronRightOutline} from "react-icons/ti";
import "./Slider.scss";
import Avatar from '@mui/material/Avatar';
import review1 from '../../photos/review1.jpg';
import review2 from '../../photos/review2.jpg';
import review3 from '../../photos/review3.jpg';
import review4 from '../../photos/review4.jpg';
import review5 from '../../photos/review5.jpg';
import review6 from '../../photos/review6.jpg';
import review7 from '../../photos/review7.jpg';
import review8 from '../../photos/review8.jpg';
import review9 from '../../photos/review9.jpg';
import review10 from '../../photos/review10.jpg';
import review11 from '../../photos/review11.jpg';
import review12 from '../../photos/review12.jpg';
import review13 from '../../photos/review13.jpg';
import review14 from '../../photos/review14.jpg';
import review15 from '../../photos/review15.jpg';
import review16 from '../../photos/review16.jpg';
import review17 from '../../photos/review17.jpg';
import review18 from '../../photos/review18.jpg';
import review19 from '../../photos/review19.jpg';
import review20 from '../../photos/review20.jpg';

const base = [
    {
      title: "Дядченко Олександр",
      text:
        "Можу рекомендувати компанію як надійного перевізника. Подача авто оперативна, у випадку непередбачуваних негараздів все вирішується швидко та позитивно. Надійний партнер!",
      img: review5,
    },
    {
      title: "Поліщук Оксана Анатоліївна",
      text:
        "Дякую за багаторічну плідну співпрацю. Бажаю натхнення та кар'єрного зростання. Люблю всіх вас \n" +
        "\n" +
        "Дуже вам дякую за красу, яку Ви даруєте.",
      img: review1,
    },
    {
      title: "Коваленко Дмитро",
      text: "Працюю з вами вже 9 років. Все чудово. Не проміняю вашу компанію ні на яку іншу",
      img: review2,
    },
    {
      title: "Вадим Ціпуринда",
      text:
        "Відколи почав працювати з найкращою логістичною компанією по перевезенню мінеральних добрив (з 2013 року) - все настільки ідеально, що жодного разу не було потреби звертатися за пошуком авто до іншої компанії. Порядні логісти, реальні ціни, оперативна доставка і найголовніше - надійність.",
      img: review3,
    },
    {
      title: "Мовчан Олександр Андрійович",
      text: "Надійна логістична компанія, працюємо разом вже понад 10 років, рекомендую.",
      img: review4,
    },
    {
      title: "Шутко Юрій",
      text:
        "12 років працюю з Горячою та її командою. Ще ні разу не підводили, машина знаходиться завжди. Дякую за співпрацю, далі більше!",
      img: review6,
    },
    {
      title: "Олена",
      text: "Рекомендуємо до роботи відповідального, надійного перевізника. Груз доставлений вчасно та в збереженні. Особлива подяка Михайлу за професійну роботу.",
      img: review7,
    },
    {
      title: "Олена",
      text: "Неодноразово співпрацювали. Відповідальний підхід, рекомендуємо до співпраці. Дякую",
      img: review8,
    },
    {
      title: "Ілля",
      text:
        "Налагоджено хорошу співпрацю, дуже відповідальні логісти. Дуже швидкий пошук автомобіля. Дякуємо, Алені за роботу справжнього професіонала! " +
        "ТОВ АГРОНОМІЧНА КОМПАНІЯ",
      img: review9,
    },
    {
      title: "Струтинська Лілія",
      text:
        "Дуже дякую за співпрацю, за своєчасну допомогу, за щирі людяні відношення. Приємно працювати з Фахівцями своєї справи. Бажаю гарних клієнтів та успіхів. ТОВ Ванукрстрой",
      img: review10,
    },
    {
      title: "Задаєнна Вікторія",
      text:
        "Спасибі за оперативну і якісну роботу! Кращий підхід до клієнтів! Підбір надійних і перевірених перевізників, повний контроль перевезення, кращі ціни на ринку України! ТОВ 'Агро-Торговий Союз.Центр'",
      img: review11,
    },
    {
      title: "Лисенко Каріна",
      text:
        "Співпрацюємо вже не один рік. За цей час зарекомендували себе як надійний партнер та професійна команда. Висловлюю подяку та сподіваюся на подальшу плідну співпрацю. ТОВ 'САРС Агро'",
      img: review12,
    },
    {
      title: "Панчев Максим",
      text:
        "Можливо найкращі логісти України. Користуюся їхніми послугами дуже давно. Завжди швидко і оперативно. Дякую за професійний підхід до роботи. Тов ЦІІАТ",
      img: review13,
    },
    {
      title: "Анастасія",
      text:
        "З ФОП Горячий працюємо вже не перший рік. Надають нам транспортні послуги з перевезення добрив та КАСу. Завжди все чітко і з оформленням документів, і з строками доставки нашого вантажу. Менеджер завжди на зв'язку, ввічливі водії, які знають свою справу.\n",
      img: review14,
    },
    {
      title: "Полосмінкін Ігор",
      text:
        "Дякуємо за оперативну та якісну доставку вантажів. Бажаємо Вам успіхів і вдячних клієнтів. ТОВ 'Агроресурс-А'",
      img: review15,
    },
    {
      title: "Бишовець Сергій",
      text:
        "Дякую за якісно виконану роботу в сфері вантажоперевезень. Перевагами даної компанії є надійність, оперативність і комфорт. Вони надають клієнту повний спектр логістичних послуг. ПП Оптималбуд",
      img: review16,
    },
    {
      title: "Назаренко Яна",
      text:
        "Дякую за багаторічну плідну співпрацю. Завжди вчасно і без проблем. Логісти дуже ввічливі, завжди знаходять вихід! Бажаю кар'єрного зростання.",
      img: review17,
    },
    {
      title: "Корольова Ірина",
      text:
        "Рекомендуємо до співпраці! Всі питання вирішувались позитивно і оперативно. Бажаємо вашій компанії і всім працівникам розвитку і процвітання. ТОВ Фрея Трейд",
      img: review18,
    },
    {
      title: "Євген 'Українська Агропромислова група'",
      text:
        "Мені дуже приємно працювати з цією компанією. Хочу відзначити, що за час нашої співпраці співробітники компанії завжди працюють оперативно і якісно. Для них немає складнощів у перевезенні вантажів, завжди все на вищому рівні.\n" +
        "Ви молодці, ви круті) \n" +
        "Так тримати!!!",
      img: review19,
    },
    {
      title: "ТОВ 'Агро-Д.О.М.-Трейдінг' Дмитро",
      text:
        "За десять років роботи з компаніями по перевезенню вантажів,  Я нарешті знайшов надійних партнерів і це команда The Hot Logistics. Дякую за надійність, швидкість. Команда The Hot Logistics це не тільки партнери, а й справжні друзі!!!!",
      img: review20,
    },
  ];
  

const images = [];
images.push(...base);
images.push(...base);
images.push(...base);
images.push(...base);
images.push(...base);
images.push(...base);
images.push(...base);

const MAX_VISIBILITY = 5;

const Card = ({title, content, img}) => (
    <grid item xs={10} sm={10} md={6}>
        <div className='card'>
            <Avatar src={img} sx={{ width: 100, height: 100 }} />
            <h3>{title}</h3>
            <p>{content}</p>
        </div>
    </grid>
);

const Carousel = ({children}) => {
    const [active, setActive] = useState(38);
    const count = React.Children.count(children);

    return (
            <div xs={10} sm={10} md={6} className='carousel'>
                {active > 0 && <button className='nav left' onClick={() => setActive(i => i - 1)}><TiChevronLeftOutline/></button>}
                {React.Children.map(children, (child, i) => (
                    <div className='card-container' style={{
                        '--active': i === active ? 1 : 0,
                        '--offset': active === i ? 'none' : (active - i) / 3,
                        '--abs-offset': Math.abs(active - i) / 3,
                        'pointer-events': active === i ? 'auto' : 'none',
                        'opacity': Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
                        'display': Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
                    }}>
                        {child}
                    </div>
                ))}
                {active < count - 1 && <button className='nav right' onClick={() => setActive(i => i + 1)}><TiChevronRightOutline/></button>}
            </div>
    );
};

const App = () => (
    <div className='app'>
        <Carousel>
            {images.map((step, index) => (
                <Card title={step.title} content={step.text} img={step.img}/>
            ))}
        </Carousel>
    </div>
);

export default App;
