import React from "react";
import "./CivilLaw.css";

function BusinessRegistration() {
  return (
    <div className="service-container">
      <h1><span className="checkmark">✔</span> Реєстрація бізнесу під ключ🔐</h1>
      <h1><span className="checkmark">✔</span> Ведення бухгалтерського обліку та кадрів, оптимізація податкового навантаження 📊</h1>
      <h1><span className="checkmark">✔</span> Професійна консультація з приводу ведення ФОП та ЄП🧮</h1>
      <h1><span className="checkmark">✔</span> Підвищення кваліфікації вашого бухгалтера👩‍💻📈</h1>
      <h1><span className="checkmark">✔</span> Онлайн підтримка 24/7⏰️</h1>
    </div>
  );
}

export default BusinessRegistration;
