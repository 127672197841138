import React from 'react';
import classes from "./Icons.module.scss";
import Container from "@material-ui/core/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { faQuinscape } from '@fortawesome/free-brands-svg-icons';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faMale } from '@fortawesome/free-solid-svg-icons';
import Typography from '../components/Typography';

const advantages = [
  {
    "icon": faHistory,
    "text": "Економія вашого часу"
  },
  {
    "icon": faTasks,
    "text": "Експертиза перевезень"
  },
  {
    "icon": faQuinscape,
    "text": "Масштабування бізнесу"
  },
  {
    "icon": faHandHoldingUsd,
    "text": "Економія ваших витрат"
  },
  {
    "icon": faMapMarkedAlt,
    "text": "Створення ланцюжка поставок"
  },
  {
    "icon": faUserAlt,
    "text": "Забезпечення лояльності клієнтів"
  },
  {
    "icon": faExclamationTriangle,
    "text": "Управління ризиками"
  },
  {
    "icon": faMale,
    "text": "Ваш надійний перевізник 24/7"
  }
];

function Icons() {
  return (
    <section className={classes.root} style={{ backgroundColor: 'black' }}>
      <Container>
        <div className={classes.container}>
          {advantages.map(item => (
            <div key={item.text} className={classes.itemContainer}>
              <div className={classes.iconContainer}>
                <FontAwesomeIcon className={classes.icon} icon={item.icon} style={{ color: 'white' }} />
              </div>
              <Typography className={classes.text} style={{ color: 'white' }}>{item.text}</Typography>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default Icons;
