import React from 'react';
import EmployeeDetails from "./EmployeeDetails";
import classes from './Employee.module.scss'
import {Container} from "@mui/material";

function Employee(props) {
    return (
        <Container className={classes.root}>
            <div className={classes.item}>
                <div className={classes.info}>
                    <EmployeeDetails user={props.user}/>
                </div>
                <div className={classes.photo}>
                    <img
                        src={props.user.img}
                        alt="call to action"
                        className={classes.image}
                    />
                </div>

            </div>
        </Container>

    );
}

export default Employee;
