import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './dropdown-menu.css';

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
      <button className="dropdown-menu__toggle" onClick={toggleMenu}>
        Меню
      </button>
      <div className="dropdown-menu__content">
        <ul className="dropdown-menu__list">
          <li>
            <NavLink exact to="/" activeClassName="active">
              Логістика
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/jurisprudence" activeClassName="active">
              Юриспруденція
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/accounting" activeClassName="active">
              Бухгалтерія
            </NavLink>
          </li>
          <li>
            <NavLink to="/contacts" activeClassName="active">
              Контакти
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DropdownMenu;
