import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import appFooterFacebook from '../../photos/appFooterFacebook.png';
import appFooterInstagram from '../../photos/appFooterInstagram.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  iconsWrapper: {
    height: 100,
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  text: {
    color: 'white'
  },
}));


export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justify="flex-end"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item className={classes.icons}>
                <a href="https://www.facebook.com" className={classes.icon}>
                  <img src={appFooterFacebook} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com" className={classes.icon}>
                  <img src={appFooterInstagram} alt="Instagram" />
                </a>
                <a className={classes.text}>
                  <Typography>thehotlogistics.com&nbsp;&copy;</Typography>
                </a>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={6} sm={8} md={4}>
          </Grid>
          <Grid item>

          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
