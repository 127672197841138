import React from 'react';
import Footer from "./modules/views/Footer";
import AppAppBar from "./modules/views/AppAppBar";
import "./JurisprudencePage.css";
import './modules/views/styles.scss';
import Prod from "./modules/views/ProductHeroCon"
import Alona from './modules/views/WorkerAlona';
import Mykhailo from './modules/views/WorkerMykhailo';
import Lesya from './modules/views/WorkerLesya';
import Mykola from './modules/views/WorkerMykola';
import Natalia from './modules/views/WorkerNatalia';
import Olena from './modules/views/WorkerOlena';
import Evgeniy from './modules/views/WorkerEvgeniy';
import Anna from './modules/views/WorkerAnna';
import Tetyana from './modules/views/WorkerTetyana';
import Vadim from './modules/views/WorkerVadim';
import Luba from './modules/views/WorkerLuba';

const styles = (theme) => ({
  backgroundLight: {
    backgroundColor: theme.palette.secondary.light,
  },
  backgroundMain: {
    backgroundColor: theme.palette.secondary.main,
  },
  backgroundDark: {
    backgroundColor: theme.palette.secondary.dark,
  },
  padding: {
    padding: theme.spacing(1),
  },
});



function Contacts() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Prod/>
      <div className="workers-list">
        <Alona />
        <Mykhailo />
        <Lesya />
        <Mykola />
        <Natalia />
        <Olena />
        <Evgeniy />
        <Vadim />
        <Anna />
        <Tetyana />
        <Luba />        
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Contacts;

