import React from 'react';
import classes from "./Icons.module.scss";
import Container from "@material-ui/core/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faMale } from '@fortawesome/free-solid-svg-icons';
import { faSchool } from '@fortawesome/free-solid-svg-icons';
import Typography from '../components/Typography';

const advantages1 = [
  {
    "icon": faHistory,
    "text": "Забезпечити правильне та своєчасне подання фінансової та податкової звітності"
  },
  {
    "icon": faHandHoldingUsd,
    "text": "Підібрати оптимальну систему оподаткування"
  },
  {
    "icon": faUserAlt,
    "text": "Мати можливість он-лайн  навчання та підвищення кваліфікації співробітників вашого офісу"
  },
  {
    "icon": faMale,
    "text": "Отримати бухгалтерський супровід 24/7"
  },
  {
    "icon": faSchool,
    "text": "Навчання бухгалтера з нуля"
  }
];

function Icons1() {
  return (
    <section className={classes.root}>
      <Container>
        <Typography variant="h4" align="center" color="textPrimary" style={{ color: '#000', marginBottom: '1rem' }}>
          З нами ви зможете:
        </Typography>
        <div className={classes.container}>
          {advantages1.map(item => (
            <div key={item.text} className={classes.itemContainer}>
              <div className={classes.iconContainer}>
                <FontAwesomeIcon className={classes.icon} icon={item.icon} style={{ color: 'black' }} />
              </div>
              <Typography className={classes.text} style={{ backgroundcolor: 'black', color: 'black' }}>{item.text}</Typography>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default Icons1;
