import React, { useState, useRef, useEffect } from 'react';
import alonaImage from './Employees/photos/Jenia.jpg';
import productBuoy from '../../photos/productBuoy.svg';
import classes from "./EmployeeDetails.module.scss";
import Button from '../components/Button';

const WorkerAlona = () => {
  const [isHovered, setIsHovered] = useState(false);
  const workerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (workerRef.current) {
        const rect = workerRef.current.getBoundingClientRect();
        const centerY = window.innerHeight / 2;

        setIsHovered(rect.top < centerY && rect.bottom > centerY);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const workerStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: '23px',
    position: 'relative',
  };

  const imageStyle = {
    marginBottom: '10px',
    marginTop: '20px',
    borderRadius: '50%',
    width: '320px',
    height: '320px',
  };

  const overlayStyle = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    pointerEvents: 'none',
    zIndex: 1,
    transition: 'opacity 0.9s, box-shadow 0.9s',
    boxShadow: isHovered
      ? 'inset 0 0 100px rgba(0, 255, 0, 0.8)'
      : 'inset 0 0 0 rgba(0, 255, 0, 0)',
  };

  const lineStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '2px',
    backgroundColor: 'white',
    transition: 'opacity 0.3s',
    opacity: isHovered ? 1 : 0,
  };

  const viberIconStyle = {
    marginLeft: '15px',
    marginTop: '-10px',
    
  };

  const contactButtonsStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    
  };

  const phoneCall = () => {
    return "tel:380673050902";
  };

  const viber = () => {
    const woPlus = '380673050902';
    return "viber://chat?number=%2B" + woPlus;
  };

  const buttonStyle = {
    color: 'white', // Задайте колір тексту чорним
    backgroundColor: 'black', // Задайте фон білим (або іншим за потребою)
  };


  return (
    <div
      ref={workerRef}
      className="worker"
      style={workerStyle}
    >
      <div style={overlayStyle}>
        <div style={{ ...lineStyle, top: 0 }} />
        <div style={{ ...lineStyle, bottom: 0 }} />
      </div>

      <div className="worker" style={workerStyle}>
        <img src={alonaImage} alt="Alona" style={imageStyle} />
        <div>
          <h2>ЗУБАЧ ЄВГЕНІЙ ЮРІЙОВИЧ</h2>
          <p>Логіст відділу поставок і просто красавчик</p>
        </div>
        <div style={contactButtonsStyle}>
          <div>
            <Button
            href={phoneCall()}
            type="submit"
            color="primary"
            variant="contained"
            style={buttonStyle}
          >
              Зателефонувати &nbsp;{"+380679461407"}
            </Button>
          </div>
          <a href={viber()} style={viberIconStyle}>
            <img src={productBuoy} className={classes.buoy} alt="buoy" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default WorkerAlona;
