import React from 'react';
import Footer from "./modules/views/Footer";
import AppAppBar from "./modules/views/AppAppBar";
import BusinessRegistration from "./modules/views/BusinessRegistration";
import Buh from "./modules/views/buh";
import ProductValuesBuh from "./modules/views/ProductValuesBuh";
import Icons from "./modules/views/IconsBuh";
import Employees from "./modules/views/EmployeesBuh/Employees";

function AccountingPage(){
  return (
    <React.Fragment>
      <AppAppBar/>
      <Buh/>
      <ProductValuesBuh/>
      <Icons/>
      <BusinessRegistration />
      <Employees />
      <Footer/>
      </React.Fragment>
  );
};

export default AccountingPage;