import React, { useRef } from 'react';
import "./Footer.scss";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Grid from '@mui/material/Grid';
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles";


const styles = (theme) => ({
    title: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    left: {
      alignItems: "center",
    },
    leftLinkActive: {
      color: theme.palette.common.white,
    },
    right: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    rightLink: {
      fontSize: 14,
      color: theme.palette.common.white,
      marginRight: 15,
    },
    linkSecondary: {
      color: theme.palette.secondary.main,
    },
    
});
function Footer() {
    const phoneNumberRef = useRef(null);
    const emailRef = useRef(null);

    const copyToClipboard = (ref, label) => {
        let textToCopy = '';
        if (label === 'Телефон') {
            textToCopy = '+38096341617';
        } else if (label === 'Електронну пошту') {
            textToCopy = '0966341617@ukr.net';
        }

        navigator.clipboard.writeText(textToCopy);
        alert(`${label} скопійовано в буфер обміну.`);
    };

    return (
            <Grid className="footer-dark">
                <footer>
                    <Container className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-3 item">
                                <h3>Контакти</h3>
                                <ul>
                                    <li><a>м. Черкаси, бульвар Шевченка, 145, офіс 321</a></li>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2593.87545021587!2d32.051619!3d49.449071!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d14d50ed42dfb9%3A0x40562cf61d37d1df!2sThe%20Hot%20Logistics!5e0!3m2!1sru!2sua!4v1703323072049!5m2!1sru!2sua"/>
                                </ul>
                            </div>
                            <div className="col-sm-6 col-md-3 item">
                                <br/>
                                <h3>Графік работи</h3>
                                <ul>
                                  <h4 className="textLayout2">Цілодобово</h4>
                                    <a href="tel:+38096341617" className="textLayout2">Телефон: +38 (096) 634 1617 (Альона)</a>
                                    <button
    onClick={() => copyToClipboard(phoneNumberRef, 'Телефон')}
    style={{
        backgroundColor: 'green',
        border: 'none',
        color: 'white',
        padding: '5px 10px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '13px',
        borderRadius: '10px',
        cursor: 'pointer',
        marginTop: '5px',
        marginLeft: '25px', 
        marginRight: 'auto', 
    }}
>
    Копіювати
</button>
                                    <br/>
                                    <a href="https://mail.ukr.net/desktop#sendmsg" className="textLayout2" >Електронна пошта: 0966341617@ukr.net</a>
                                    <button
                                onClick={() => copyToClipboard(emailRef, 'Електронну пошту')}
                                style={{
                                    backgroundColor: 'green',
                                    border: 'none',
                                    color: 'white',
                                    padding: '5px 10px',
                                    textAlign: 'center',
                                    textDecoration: 'none',
                                    display: 'inline-block',
                                    fontSize: '13px',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    marginTop: '5px',
                                    marginLeft: '15px', 
        marginRight: 'auto', 
                                }}
                            >
                                Копіювати
                            </button>
                                    

                                </ul>
                            </div>
                            <br/>
                            <div className="col-md-6 item text">
                                <h3>The Hot Logistics</h3>
                                <h4 className="textLayout2">Наша логістична компанія не тільки відповідає високим вимогам клієнтів щодо якості послуг, а й задає власні стандарти, які часто перевищують очікування замовників. Ми створили комплексну систему, завдяки якій наші логістичні послуги під надійним управлінням та контролем якості. Ця система допомагає оптимізувати всі процеси, зробити умови для клієнтів комфортнішими, а якість послуг – найвищою на ринку логістики.</h4>
                                <h2 className="textLayout">Вантажні перевезення.</h2>
                                <h4 className="textLayout2">Наша компанія забезпечує доставку вантажів не лише в Україні, а й за її межами. Наші фахівці оперативно приймуть Ваше замовлення, обговорять всі необхідні умови.</h4>
                                <h2 className="textLayout">Замовити фуру, інший транспорт. </h2>
                                <h4 className="textLayout2">Наша компанія має великий автопарк для перевезення будь-якого типу  вантажу в будь-яке  вказане Вами місце, місто, країну.</h4>
                                <h2 className="textLayout">Перевезення вантажів. </h2>
                                <h4 className="textLayout2">Транспорт для габаритних та негабаритних вантажів, небезпечні вантажі, вантажі більше 20 тон, спеціальні вантажі – все це може забезпечити Вам наша компанія.</h4>
                                <h2 className="textLayout">Транспортні послуги.</h2>
                                <h4 className="textLayout2">Компанія має за плечима понад 14 років роботи на ринку вантажоперевезень та логістичних послуг. Усі товари, які ми приймаємо до перевезення -  застраховані. Маємо великий досвід у міжнародній логістиці. Все це завдяки високим стандартам та системному підходу.</h4>
                            </div>
                            <div className="icons">
                                <a className="icons" href="https://www.facebook.com/hotLogistics/">
                                    <FacebookIcon fontSize="large"/>
                                </a>
                                <a className="icons" href="https://www.instagram.com/hot_logistics/?utm_medium=copy_link">
                                    <InstagramIcon fontSize="large"/>
                                </a>
                            </div>
                        </div>
                        <p className="copyright">The Hot Logistics © 2023</p>
                    </Container>
                </footer>
            </Grid>
    );
}
export default Footer;
